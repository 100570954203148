export function formatString(str: string): string {
	return str
		.replace(/\\(["'`|])/g, "$1") // Замена экранированных символов
		.replace(/&(amp|lt|gt|quot|apos|nbsp);/g, (match, entity) => {
			switch (entity) {
				case "amp":
					return "&";
				case "lt":
					return "<";
				case "gt":
					return ">";
				case "quot":
					return '"';
				case "apos":
					return "'";
				case "nbsp":
					return " ";
				default:
					return match;
			}
		});
}
