import { ENV } from "./constants";

const appId = `kassir_ru_${ENV === "prod" ? "int" : "dev"}`;
const platform = ENV === "prod" ? "mob" : "web";
const endpoint =
	ENV === "prod"
		? "metrics.alfabank.ru/metrica/retail"
		: "testjmb.alfabank.ru/metrica/retail";

// @ts-ignore
const hasSnowplow = typeof window !== "undefined" && !!window.sp;

if (hasSnowplow) {
	// @ts-ignore
	sp("newTracker", "ab", endpoint, {
		appId,
		platform,
		discoverRootDomain: true,
		forceSecureTracker: true,
	});
}

export function snowplow(...args: any[]) {
	if (hasSnowplow) {
		let trackingId = window._trackingId;

		if (!trackingId) {
			const cached = localStorage.getItem("trackingId");

			if (cached) {
				window._trackingId = cached;
				trackingId = cached;
			}
		}

		// console.log("sp:", args);
		// @ts-ignore
		sp("setUserId", trackingId);
		// @ts-ignore
		sp(...args);
	}
}

export function trackScreenView(
	eventLabel: string,
	pageTitle = document.title,
	dimensions?: {
		title?: string;
		action?: string;
		sort?: string;
		filterName?: string;
		filter?: string;
		symbol?: string;
		content?: string | boolean;
		contentId?: string;
	},
) {
	return snowplow(
		"trackStructEvent",
		"Partners promotions",
		"Screen View",
		eventLabel,
		null,
		null,
		[
			{
				schema: "iglu:com.alfabank/custom_dimension/jsonschema/1g-0-0",
				data: {
					device_screen_name: pageTitle,
					"1": dimensions?.action,
					"14": dimensions?.filterName,
					"13": dimensions?.filter,
					"12": dimensions?.sort,
					"3": dimensions?.symbol,
					"4": dimensions?.content,
					"5": dimensions?.contentId,
					"6": pageTitle,
				},
			},
		],
	);
}

export function trackEvent(
	label: string,
	action: string,
	dimensions: {
		title?: string;
		details?: string;
		action?: string;
		sort?: string;
		filterName?: string;
		filter?: string;
		symbol?: string;
		content?: string | boolean;
		contentId?: string;
		selection?: string;
	} = {},
) {
	const { title = document.title } = dimensions;

	return snowplow(
		"trackStructEvent",
		"Partners promotions",
		action,
		label,
		null,
		null,
		[
			{
				schema: "iglu:com.alfabank/custom_dimension/jsonschema/1-0-0",
				data: {
					device_screen_name: title,
					"1": dimensions.action,
					"2": dimensions.details,
					"14": dimensions.filterName,
					"13": dimensions.filter,
					"12": dimensions.sort,
					"3": dimensions.symbol,
					"4": dimensions.content,
					"5": dimensions.contentId,
					"6": title,
					"7": dimensions.selection,
				},
			},
		],
	);
}
