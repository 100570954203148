const EMAIL_REGEX =
	/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

export function validateEmail(value: string) {
	if (!value) {
		return "E-mail не должен быть пустым"; // Проверка на пустую строку
	}

	if (!EMAIL_REGEX.test(value)) {
		return "Некорректный E-mail"; // Ошибка по регулярному выражению
	}

	return true; // Все в порядке
}

const NAME_REGEX = /^[а-яё]+(?:[-][а-яё]+)*\s[а-яё]+(?:\s[а-яё]+)*$/i;

export function validateName(value: string) {
	if (!value) {
		return "ФИО не должно быть пустым";
	}

	if (!NAME_REGEX.test(value)) {
		return "Некорректное ФИО";
	}

	return true;
}
