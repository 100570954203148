import cx from "classnames";

import styles from "./Page.module.css";

type PageProps = {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
};

const Root: React.FC<PageProps> = ({ children, className, ...props }) => {
	return (
		<div className={cx(styles.root, className)} {...props}>
			{children}
		</div>
	);
};

type PageContainerProps = {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
};

const PageContainer: React.FC<PageContainerProps> = ({
	children,
	className,
	...props
}) => {
	return (
		<div className={cx(styles.container, className)} {...props}>
			{children}
		</div>
	);
};

const PageSpacer: React.FC = () => {
	return <div className={styles.spacer} />;
};

export const Page = Object.assign(Root, {
	Container: PageContainer,
	Spacer: PageSpacer,
});
