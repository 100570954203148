import { Suspense } from "react";
import ReactDOM from "react-dom/client";

import { PageLoader } from "./shared/ui/page-loader";
import { AppProvider } from "./shared/lib";
import { initErrorTracker } from "./shared/error";
import { Router } from "./Router";

initErrorTracker();

import "./index.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<Suspense fallback={<PageLoader />}>
		<AppProvider>
			<Router />
		</AppProvider>
	</Suspense>,
);
