import { post, put } from "../fetch";

export async function auth(args: { email: string }) {
	return post<"OK" | false>("/auth", { email: args.email });
}

export type AlfaAuthResult = {
	cityId: number;
	trackingId: string;
};

export async function alfaAuth(args: { guid: string }) {
	return post<AlfaAuthResult>("/alfa_auth", { guid: args.guid });
}

export type Profile = {
	name: string;
	phone: string;
	email: string;
	emailConfirmed: boolean;
	nextEmailConfirmationAvailableAt: string | null;
	alfaIdConnected: boolean;
	alfaAuthDeclined: boolean;
};

type ProfileResult = Profile;

export async function getProfile() {
	return post<ProfileResult>("/profile", {});
}

export async function updateProfile(args: {
	name?: string;
	phone?: string;
	email?: string;
}) {
	return put<ProfileResult>("/profile", args);
}

export async function getAlfaIDAuthURL(args: {
	nextRoute: string;
}) {
	return post<{
		url: string;
	}>("/alfa_id_auth_url", {
		next_route: args.nextRoute,
	});
}

export function alfaIDAuth(args: {
	code: string | null;
	state: string;
	declined: boolean;
}) {
	return post<{
		nextRoute: string;
		profile: {
			name: string;
			phone: string;
			email: string;
			alfaIdConnected: false;
			alfaAuthDeclined: false;
		};
	}>("/alfa_id_auth_code", args);
}
