import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

import { APIError } from "@/shared/error";
import { Platform, detectPlatform } from "@/shared/utils";
import { logger } from "@/shared/logger";

import { ErrorPage } from "./ErrorPage";

const ALFA_VIEW_HOME_URI =
	"alfabank://webFeature?type=recommendation&url=https%3A%2F%2Fweb.alfabank.ru%2Fpartner-offers%2Fafisha-transfer";

const ALFA_WEB_HOME_URI = "/city";

export const ErrorBoundary: React.FC = () => {
	const error = useRouteError() as Error;

	useEffect(() => {
		logger.error(error.message, error);
	}, []);

	const isWeb = detectPlatform() === Platform.WEB;
	const homeUrl = isWeb ? ALFA_WEB_HOME_URI : ALFA_VIEW_HOME_URI;

	if (error instanceof APIError) {
		if (isWeb && error.error === -1)
			return (
				<ErrorPage
					title="Не получилось загрузить"
					message="Вернитесь в Альфа-Онлайн и попробуйте ещё раз"
					actions={[
						{
							label: "Перейти в Альфа-Онлайн",
							callback: () => window.location.replace(homeUrl),
						},
					]}
				/>
			);
	}

	return (
		<ErrorPage
			message="На нашей стороне, но мы уже чиним"
			actions={[
				{
					label: "Вернуться на главный экран",
					callback: () => window.location.replace(homeUrl),
				},
			]}
		/>
	);
};
