export function plural(forms: [string, string, string], n: number): string {
	const absN = Math.abs(n);
	let formIndex: number;

	if (absN % 10 === 1 && absN % 100 !== 11) {
		formIndex = 0; // Единственное число
	} else if (
		absN % 10 >= 2 &&
		absN % 10 <= 4 &&
		(absN % 100 < 10 || absN % 100 >= 20)
	) {
		formIndex = 1; // Несколько (от 2 до 4)
	} else {
		formIndex = 2; // Много (5 и более)
	}

	return forms[formIndex] || "";
}
