export const MAX_CITIES_HISTORY_ITEMS = 5;
export const MAX_PLACES_HISTORY_ITEMS = 5;
export const MAX_SEARCH_RESULTS = 50;
export const MAX_POPULAR_PLACES = 15;

export const ENV =
	typeof process !== "undefined"
		? (process.env.PUBLIC_DEPLOY_ENV as "devstg" | "prod")
		: "prod";

export const API_ENDPOINT = {
	devstg: "https://api-alfa-test.kassir.ru",
	prod: "https://api-alfa.kassir.ru",
}[ENV];

export function getAPIEndpoint() {
	if (location.host === "www.alfa-dev1.kassirdev.ru") {
		return "https://api.alfa-dev1.kassirdev.ru";
	}

	if (location.host === "www.alfa-dev2.kassirdev.ru") {
		return "https://api.alfa-dev2.kassirdev.ru";
	}

	if (location.host === "alfa-test.kassir.ru") {
		return "https://api-alfa-test.kassir.ru";
	}

	if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
		return "https://api-alfa-test.kassir.ru";
	}

	return "https://api-alfa.kassir.ru";
}

export const SENTRY_DSN =
	"https://3749b9af2f833f0424cc7fbb7b22bc17@o4508212121108480.ingest.de.sentry.io/4508212123336784";
