import { post } from "../fetch";

export type SuccessPayResult = {
	orderId: number;
	message: string;
};

export async function successPay(args: {
	uid: string;
	oid: number;
	bid: string;
}) {
	return post<SuccessPayResult>("/success_pay", {
		uid: args.uid,
		oid: args.oid,
		bid: args.bid,
	});
}

export type FailPayResult = {
	orderId: number;
	message: string;
	advice: string;
};

export async function failPay(args: {
	uid: string;
	oid: number;
	bid?: string;
}) {
	return post<FailPayResult>("/fail_pay", {
		uid: args.uid,
		oid: String(args.oid),
		bid: args.bid,
	});
}
