import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ErrorBoundary } from "@/shared/ui/error";
import { ENV } from "@/shared/constants";

import { AppWrapper } from "./pages/_app";

const router = createBrowserRouter([
	{
		element: <AppWrapper />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				children: [
					// Onboarding page (dev only)
					ENV !== "prod"
						? { path: "/", lazy: () => import("./pages/onboarding") }
						: {},
					// Auth callback routes
					{
						path: "/alfa-auth/:guid",
						lazy: () => import("./pages/auth"),
					},
					{
						path: "/alfa/id",
						lazy: () => import("./pages/alfa-id"),
					},
					{
						path: "/email/confirmation",
						lazy: () => import("./pages/email-confirmation"),
					},
					// Promo routes
					{
						path: "/alfa_recommends_24_29_05_24",
						lazy: () =>
							import("./pages/promo/alfa_recommends_24_29_05_24/AlfaFriday"),
					},
					{
						path: "/alfa_recommends_24_29_06_24",
						lazy: () =>
							import("./pages/promo/alfa_recommends_24_29_06_24/AlfaFriday"),
					},
				],
			},
			{
				lazy: () => import("./pages/_main"),
				shouldRevalidate: () => false,
				children: [
					{
						path: "/city",
						lazy: () => import("./pages/cities"),
					},
					{
						path: "/account",
						lazy: () => import("./pages/account"),
					},
					{
						path: "/profile",
						lazy: () => import("./pages/account/profile"),
					},
					{
						path: "/faq",
						lazy: () => import("./pages/account/faq"),
					},
					{
						lazy: () => import("./pages/_actions"),
						// @ts-ignore
						shouldRevalidate: ({ currentParams, nextParams }) => {
							return currentParams.cid !== nextParams.cid;
						},
						children: [
							{
								path: "/city/:cid",
								lazy: () => import("./pages/city"),
								// @ts-ignore
								shouldRevalidate: ({ currentParams, nextParams }) => {
									return currentParams.cid !== nextParams.cid;
								},
							},
							{
								lazy: () => import("./pages/_filters"),
								children: [
									{
										path: "/city/:cid/filters",
										lazy: async () => import("./pages/filters"),
									},
									{
										path: "/city/:cid/filters/city",
										lazy: () => import("./pages/filters/city"),
									},
									{
										path: "/city/:cid/filters/genres",
										lazy: () => import("./pages/filters/genres"),
									},
									{
										path: "/city/:cid/filters/venues",
										lazy: () => import("./pages/filters/venues"),
									},
									{
										path: "/city/:cid/filters/date",
										lazy: () => import("./pages/filters/date"),
									},
								],
							},
							{
								path: "/city/:cid/date",
								lazy: () => import("./pages/date"),
							},
							{
								path: "/city/:cid/search",
								lazy: () => import("./pages/search"),
							},
							{
								id: "action",
								lazy: async () => {
									const { loader } = await import("./pages/action");
									return { loader };
								},
								// @ts-ignore
								shouldRevalidate: ({ currentParams, nextParams }) => {
									return currentParams.action !== nextParams.action;
								},
								children: [
									{
										path: "/city/:cid/:action",
										lazy: async () => {
											const { Component } = await import("./pages/action");
											return { Component };
										},
									},
									{
										path: "/city/:cid/:action/reservation/:eventId",
										lazy: () => import("./pages/reservation"),
									},
								],
							},
						],
					},
					{
						path: "/checkout",
						lazy: () => import("./pages/checkout/Route"),
					},
					{
						path: "/city/:cid/selections/:sid",
						lazy: () => import("./pages/selections"),
					},
					{
						path: "/docs",
						lazy: () => import("./pages/account/docs"),
					},
					{
						path: "/orders",
						lazy: () => import("./pages/account/orders"),
					},
					{
						id: "tickets",
						lazy: async () => {
							const { loader } = await import("./pages/account/orders/tickets");
							return { loader };
						},
						shouldRevalidate: () => false,
						children: [
							{
								path: "/orders/:oid",
								lazy: async () => {
									const { Component } = await import(
										"./pages/account/orders/tickets"
									);
									return { Component };
								},
							},
							{
								path: "/orders/:oid/refund",
								lazy: () => import("./pages/refund"),
							},
							{
								path: "/orders/:oid/refund-reason",
								lazy: () => import("./pages/refund/refund-reason"),
							},
							{
								path: "/orders/:oid/automatic-refund",
								lazy: () => import("./pages/refund/automatic-refund"),
							},
							{
								path: "/orders/:oid/manual-refund",
								lazy: () => import("./pages/refund/manual-refund"),
							},
						],
					},
					{
						path: "/success/:oid/:uid",
						lazy: () => import("./pages/payment/success-payment"),
					},
					{
						path: "/fail/:oid/:uid",
						lazy: () => import("./pages/payment/failed-payment"),
					},
					{
						path: "/refund-policy",
						lazy: () => import("./pages/refund/refund-policy"),
					},
					{
						path: "*",
						lazy: () => import("./pages/not-found"),
					},
				],
			},
		],
	},
]);

export const Router: React.FC = () => {
	return <RouterProvider router={router} />;
};
