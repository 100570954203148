let APIKey: string | null = null;

export function getApiKey(): string | null {
	const searchParams = new URLSearchParams(location.search);

	return (
		searchParams.get("api_key") || APIKey || localStorage.getItem("api_key")
	);
}

export function setToken(token: string) {
	APIKey = token;

	// Save token to local storage
	localStorage.setItem("api_key", token);
}
