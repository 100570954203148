import { ENV } from "@/shared/constants";
import { captureException } from "@/shared/error";

function info(message: string, ...args: any[]) {
	if (ENV !== "prod") {
		console.log(message, ...args);
	}
}

function error(message: string, error: Error) {
	if (ENV !== "prod") {
		console.error(message, error);
	} else {
		captureException(error);
	}
}

export const logger = {
	info,
	error,
};
