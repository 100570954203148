import { createContext, useContext } from "react";

import { apiService, type Profile } from "@/shared/api";

type ProfileContextValue = {
	profile: Profile;
	updateProfile: (
		args: Partial<Pick<Profile, "email" | "name" | "phone">>,
	) => Promise<Profile>;
};

export const ProfileContext = createContext<ProfileContextValue | null>(null);

export function useProfileContext() {
	const context = useContext(ProfileContext);

	if (!context) {
		throw new Error("Missing ProfileContext.Provider in the tree");
	}

	return context;
}

type ProfileProviderProps = {
	profile: Profile;
	onUpdateProfile: (data: Profile) => void;
	children: React.ReactNode;
};

export const ProfileProvider: React.FC<ProfileProviderProps> = ({
	profile,
	onUpdateProfile,
	children,
}) => {
	const updateProfile: ProfileContextValue["updateProfile"] = async (args) => {
		const data = await apiService.updateProfile(args);
		onUpdateProfile(data);

		return data;
	};

	return (
		<ProfileContext.Provider
			value={{
				profile,
				updateProfile,
			}}
		>
			{children}
		</ProfileContext.Provider>
	);
};
