import { set, get } from "idb-keyval";

export async function getCachedData<T>(
	key: string,
	ttl: number,
): Promise<T | null> {
	const now = new Date().getTime();
	const lastUpdate = localStorage.getItem(`${key}_last_update`);

	if (lastUpdate && Number(lastUpdate) > now - ttl) {
		return (await get(key)) || null;
	}

	return null;
}

export async function cacheData<T>(key: string, data: T) {
	await set(key, data);

	localStorage.setItem(`${key}_last_update`, String(new Date().getTime()));
}
