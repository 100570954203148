import { post } from "../fetch";

import type { NewOrder } from "./order";

export type CartSeat = {
	categoryPriceId: number;
	categoryPriceName: string;
	discount: boolean;
	nominal: number;
	number: string;
	price: number;
	row: string;
	seatId: number;
	sector: string;
	tariffPlanId?: number;
	tariffPlanName?: "string";
};

export type CartEvent = {
	cityId: number;
	cityName: string;
	venueId: number;
	venueName: string;
	actionId: number;
	actionName: string;
	eventId: number;
	eventTime: string;
	serviceCharge: number;
	fullNameRequired: boolean;
	phoneRequired: boolean;
	fanIdRequired: boolean;
	seatList: Array<{
		seatId: number;
		price: number;
		serviceCharge: number;
		nominal: number;
		discount: number;
		categoryPriceId: number;
		categoryPriceName: string;
		tariffPlanId: number;
		tariffPlanName: string;
		sector: string;
		row: string;
		number: string;
	}>;
};

export type GetCartResult = {
	currency: string;
	time: number;
	actionEventList: CartEvent[];
	totalSum: number;
	totalServiceCharge: number;
	cashback: number;
	new_orders: NewOrder;
	formUrl: string;
	userInfo: {
		mail: string;
		name: string;
		phone: string;
	};
	adjustments: {
		cashback_amount: boolean;
		trainer: string;
		info: string;
	};
	reservationExpiredAt: string;
};

export async function getCart(args: { oid?: string }) {
	return post<GetCartResult>("/get_cart", {
		oid: args.oid,
	});
}
