import {
	useCallback,
	useMemo,
	useState,
	createContext,
	useContext,
} from "react";

import type { CartItem, ItemPayload } from "./types";

export type CartContextValue = {
	limits: Record<string, number>;
	items: Record<string, CartItem>;
	totalCount: number;
	totalSum: number;
	addItems(items: Array<Omit<ItemPayload, "time">>): void;
	updateItems(
		items: Array<Omit<Partial<ItemPayload> & { key: string }, "time">>,
	): void;
	removeItems(itemKeys?: string[]): void;
	increaseItem(item: Omit<ItemPayload, "qty" | "time">): void;
	decreaseItem(key: string): void;
};

const CartContext = createContext<CartContextValue | null>(null);

export function useCartContext() {
	const context = useContext(CartContext);

	if (!context) {
		throw new Error("Missing CartContext.Provider in the tree");
	}

	return context;
}

type CartProviderProps = {
	children: React.ReactNode;
};

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
	const [items, setItems] = useState<Record<string, CartItem>>({});

	const increaseItem = useCallback<CartContextValue["increaseItem"]>(
		(item) => {
			setItems((state) => {
				const existing = state[item.key];

				return {
					...state,
					[item.key]: {
						...item,
						time: new Date().getTime(),
						qty: existing ? existing.qty + 1 : 1,
					},
				};
			});
		},
		[setItems],
	);

	const decreaseItem = useCallback<CartContextValue["decreaseItem"]>(
		(key) => {
			setItems((state) => {
				const existing = state[key];

				if (existing) {
					if (existing.qty === 1) {
						const newState = { ...state };

						delete newState[key];

						return newState;
					}

					return {
						...state,
						[key]: {
							...existing,
							qty: existing.qty - 1,
						},
					};
				}

				return state;
			});
		},
		[setItems],
	);

	const addItems = useCallback<CartContextValue["addItems"]>(
		(items) => {
			for (const item of items) {
				setItems((state) => {
					const newState = { ...state };

					if (item.qty === 0) {
						delete newState[item.key];

						return newState;
					}

					newState[item.key] = {
						...item,
						time: new Date().getTime(),
					};

					return newState;
				});
			}
		},
		[setItems],
	);

	const updateItems = useCallback<CartContextValue["updateItems"]>(
		(items) => {
			for (const item of items) {
				setItems((state) => {
					const newState = { ...state };

					if (item.qty === 0) {
						delete newState[item.key];

						return newState;
					}

					const cartItem = newState[item.key];

					if (cartItem) {
						newState[item.key] = {
							...cartItem,
							...item,
							time: new Date().getTime(),
						};
					}

					return newState;
				});
			}
		},
		[setItems],
	);

	const removeItems = useCallback<CartContextValue["removeItems"]>(
		(itemKeys) => {
			setItems((state) => {
				if (itemKeys) {
					return Object.fromEntries(
						Object.entries(state).filter(([key]) => !itemKeys.includes(key)),
					);
				}

				return {};
			});
		},
		[setItems],
	);

	const limits = useMemo(() => {
		return Object.values(items).reduce(
			(acc, item) => {
				if (item.limitId) {
					acc[item.limitId] = acc[item.limitId]
						? acc[item.limitId] + item.qty
						: item.qty;
				}

				return acc;
			},
			{} as CartContextValue["limits"],
		);
	}, [items]);

	const totalCount = useMemo(() => {
		return Object.values(items).reduce((acc, item) => acc + item.qty, 0);
	}, [items]);

	const totalSum = useMemo(() => {
		return Object.values(items).reduce(
			(acc, item) => acc + item.price * item.qty,
			0,
		);
	}, [items]);

	return (
		<CartContext.Provider
			value={{
				items,
				limits,
				totalCount,
				totalSum,
				addItems,
				updateItems,
				removeItems,
				increaseItem,
				decreaseItem,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};
