import { post } from "@/shared/api/fetch";

export type EmailConfirmationResult = {
	message: string;
	advice: string;
	nextEmailConfirmationAvailableAt: string | null;
};

export async function emailConfirmation(args: { token: string }) {
	return post<EmailConfirmationResult>("/email_confirmation", {
		token: args.token,
	});
}

export function requestEmailConfirmation() {
	return post<EmailConfirmationResult>("/request_email_confirmation", {});
}
