import { post } from "../fetch";

export enum OrderStatus {
	NEW = 0,
	PROCESSING = 1,
	PAID = 2,
	CANCELLING = -1,
	CANCELLED = -2,
	REFUNDED = 3,
}

export type CreateOrderResult = {
	orderId: number;
	formUrl: string;
	statusExtStr: string;
	statusExtInt: OrderStatus;
};

export type Order = {
	actionId: string;
	actionName: string;
	actual: boolean;
	age: string;
	cityId: string;
	cityName: string;
	created: string;
	eventTime: string | null;
	currency: string;
	discount: number;
	eventId: string;
	orderId: string;
	orderIdAlt: string;
	quantity: number;
	serviceCharge: number;
	sum: number;
	userMessage: string;
	venueId: string;
	venueName: string;
	ticketList: Array<{
		ticketId: number;
		date: string;
		actionName: string;
		venueName: string;
		categoryName: string;
		price: number;
		event: number;
		sector?: string;
		row?: string;
		number?: string;
	}>;
	time?: number;
	statusExtStr:
		| "NEW"
		| "PROCESSING"
		| "PAID"
		| "CANCELLING"
		| "CANCELLED"
		| "REFUNDED";
	statusExtInt: OrderStatus;
	formUrl: string;
	localInfo?: {
		event: number;
		cityId: number;
		cityName: string;
		venueId: number;
		venueName: string;
		actionId: number;
		actionName: string;
		age: string;
	};
	reservationExpiredAt: string | null;
};

export type GetOrdersResult = {
	orderList: Array<Order>;
};

export type NewOrder = Array<{
	tix_id: number;
	pending: number;
	quantity: number;
	sum: number;
	serviceCharge: number;
	cashback: number;
	currency: string;
	formUrl: string;
	tix_city_id: number;
	tix_city_name: string;
	tix_venue_id: number;
	tix_venue_name: string;
	tix_action_id: number;
	tix_action_name: string;
	tix_event_id: number;
	tix_age: string;
	eventTime: string;
}>;

export async function createOrder(args: {
	sum: string;
	currency: string;
	phone?: string;
	name?: string;
}) {
	return post<CreateOrderResult>("/create_order", {
		sum: args.sum,
		currency: args.currency,
		phone: args.phone,
		name: args.name,
	});
}

export async function getOrders() {
	return post<GetOrdersResult>("/get_orders_ext", {});
}

export async function deleteOrder(args: { oid: number }) {
	return post<string>("/delete_order", {
		oid: args.oid,
	});
}

export function getNewOrders() {
	return post<{
		orders: number;
		// time: number;
		reservationExpiredAt: string | null;
	}>("/get_new_orders", {});
}
