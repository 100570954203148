import { createContext, useContext, useRef } from "react";
import { createStore, useStore } from "zustand";

import { type CityFilters, useCityFiltersContext } from "@/shared/lib";

type Filters = Pick<CityFilters, "genres" | "venues" | "date">;

type FiltersState = Filters & {
	setVenues: (venueIds: Filters["venues"]) => void;
	setGenres: (genresIds: Filters["genres"]) => void;
	setDate: (dates: Filters["date"]) => void;
	resetFilters: (filters?: Filters) => void;
};

const initialFiltersState: Filters = {
	genres: [],
	venues: [],
	date: null,
};

const createFiltersStore = (initProps?: Partial<Filters>) => {
	return createStore<FiltersState>()((set) => ({
		...initialFiltersState,
		...initProps,
		setVenues: (venueIds) => set({ venues: venueIds }),
		setGenres: (genresIds) => set({ genres: genresIds }),
		setDate: (dates) => set({ date: dates }),
		resetFilters: (filters) => set(filters ?? initialFiltersState),
	}));
};

type FiltersStore = ReturnType<typeof createFiltersStore>;

export const FiltersContext = createContext<FiltersStore | null>(null);

export function useFiltersContext<T>(selector: (state: FiltersState) => T): T {
	const store = useContext(FiltersContext);
	if (!store)
		throw new Error("Missing CityFiltersContext.Provider in the tree");

	return useStore(store, selector);
}

type FiltersProviderProps = {
	initialState?: Partial<Filters>;
	children: React.ReactNode;
};

export const FiltersProvider = ({
	initialState,
	children,
}: FiltersProviderProps) => {
	const cityFilters = useCityFiltersContext((state) => state);
	const storeRef = useRef<FiltersStore | null>(null);
	if (!storeRef.current) {
		storeRef.current = createFiltersStore({ ...initialState, ...cityFilters });
	}

	return (
		<FiltersContext.Provider value={storeRef.current}>
			{children}
		</FiltersContext.Provider>
	);
};
