import { post } from "../fetch";

export async function refundOrder(args: {
	oid: string;
	tids: Array<{ tid: string }>;
	name: string;
	phone: string;
	reason: string;
	comment: string;
	email: string;
}) {
	return post<true>("/refund_order", args);
}

export type GetRefundAmountResult = {
	refundingPercent: number;
	refundingSum: number;
	sum: number;
	serviceFee: number;
	ticketList: Array<{
		ticketId: number;
		price: number;
		serviceCharge: number;
		refundingPercent: number;
		refundingSum: number;
		categoryName?: string;
		row?: string;
		sector?: string;
		number?: string;
	}>;
	user: {
		name: string;
		mail: string;
		phone: string;
	};
};

export async function getRefundAmount(args: {
	oid: string;
	tids: Array<{ tid: string }>;
}) {
	return post<GetRefundAmountResult>("/get_tr_refund_amount", args);
}

export function automaticRefundOrder(args: {
	oid: string;
	tids: Array<{ tid: string }>;
}) {
	return post<true>("/automatic_refund_order", args);
}
