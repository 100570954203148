export function formatSeat(seat: {
	sector: string;
	row: string;
	number: string;
}) {
	const parts: string[] = [];

	if (seat.sector !== "-") {
		parts.push(seat.sector);
	}

	parts.push(`${seat.row} ряд`, `${seat.number} место`);

	return parts.join(", ");
}
