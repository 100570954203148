import { post } from "../fetch";

export type Kind = {
	kindId: number;
	kindName: string;
};

export type ActionEvent = {
	actionEventAlterText: string;
	categories: boolean;
	eventId: number;
	placements: boolean;
	sellEndTime: string;
	eventTime: string;
	fanIdRequired: boolean;
	cashback: number;
};

export type ActionExt = {
	actionId: number;
	actionName: string;
	address: string;
	age: string;
	description?: string;
	from: string;
	to: string;
	fullActionName: string;
	geoLat: string;
	geoLon: string;
	kdp: boolean;
	poster: string;
	venueId: number;
	venueName: string;
	events: Array<ActionEvent>;
	minPrice?: number;
	maxPrice?: number;
	cashback: number;
	when?: string;
	posterFormat: "default" | "1280x392" | "1242x800";
	soldout_events: [];
	new_orders: {
		orders: number;
		time: number;
	};
};

export type GetActionResult = ActionExt;

export function getAction(args: {
	actionId: string;
	venueId: string;
	cityId: string;
}) {
	return post<GetActionResult>("/get_action_ext", {
		aid: args.actionId,
		vid: args.venueId,
		cid: args.cityId,
	});
}
