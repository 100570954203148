import { APIError, NetworkError } from "@/shared/error";
import { logger } from "@/shared/logger";
import { getAPIEndpoint } from "@/shared/constants";

import { getApiKey, setToken } from "./auth";

type APIResponseSuccess<TDecode> = {
	type: "success";
	decode: TDecode;
	error: false;
	message: false;
	advice: false;
	system: false;
	api_key: string;
	mode: number;
};

type APIResponseError = {
	type: "warning" | "message";
	decode: false;
	error: number;
	message: string;
	advice: string;
	system: string;
	api_key: false;
	mode: number;
};

export type APIResponse<TDecode> =
	| APIResponseSuccess<TDecode>
	| APIResponseError;

function patchFetch() {
	const { fetch: origFetch, Request } = globalThis;

	globalThis.fetch = async function (
		input: RequestInfo | URL,
		init?: RequestInit,
	): Promise<Response> {
		const request = new Request(input, init);
		const stack = new Error().stack;

		try {
			return await origFetch.call(this, request);
		} catch (err) {
			if (err instanceof Error) {
				err.stack = stack;

				if (err.name === "AbortError") {
					throw err;
				}

				throw new NetworkError(err.message);
			}

			throw err;
		}
	};
}

patchFetch();

// Универсальная функция для работы с fetch
async function fetchAPI<T>(path: string, init?: RequestInit): Promise<T> {
	const url = `${getAPIEndpoint()}/json${path}`;

	logger.info(`[${init?.method || "GET"}] ${url}`, init?.body);

	const headers: Record<string, string> = {
		"Content-Type": "application/json",
		Referer: location.href,
	};

	const res = await fetch(url, {
		...init,
		headers: {
			...headers,
			...init?.headers,
		},
	});

	const data = (await res.json()) as APIResponse<T>;

	// Update API key
	if (data.api_key) {
		setToken(data.api_key);
	}

	if (data.type !== "success") {
		throw new APIError({
			type: data.type,
			code: data.error ? String(data.error) : "unknown_error",
			message: data.message || "Произошла ошибка",
			advice: data.advice,
			system: data.system,
			error: data.error,
		});
	}

	return data.decode;
}

// Универсальная функция POST-запроса
export async function post<T>(path: string, body: object): Promise<T> {
	const api_key = getApiKey();

	return fetchAPI<T>(path, {
		method: "POST",
		body: JSON.stringify({
			api_key,
			...body,
		}),
	});
}

// Универсальная функция PUT-запроса
export async function put<T>(path: string, body: object): Promise<T> {
	const api_key = getApiKey();

	return fetchAPI<T>(path, {
		method: "PUT",
		body: JSON.stringify({
			api_key,
			...body,
		}),
	});
}
