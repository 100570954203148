import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { type AllCities, apiService } from "@/shared/api";

type CitiesContextValue = {
	cities: Array<AllCities["cities"][number] & { keys: string[] }>;
	cityId: number;
	setCity: (city: { cityId: number }) => Promise<void>;
};

const CitiesContext = createContext<CitiesContextValue | null>(null);

export function useCitiesContext() {
	const context = useContext(CitiesContext);

	if (!context) {
		throw new Error("Missing CitiesContext.Provider in the tree");
	}

	return context;
}

type CitiesProviderProps = {
	cityId: number;
	cities: CitiesContextValue["cities"];
	children: React.ReactNode;
};

export const CitiesProvider: React.FC<CitiesProviderProps> = ({
	cityId,
	cities,
	children,
}) => {
	const navigate = useNavigate();

	const citiesWithKeys = cities.map((city) => ({
		...city,
		keys: [
			city.cityName,
			...(city.towns ? city.towns.map(({ cityName }) => cityName) : []),
		],
	}));

	const setCity: CitiesContextValue["setCity"] = async (city) => {
		return apiService.setCity({ cityId: city.cityId }).then(() => {
			localStorage.setItem("city", String(city.cityId));
			return navigate(`/city/${city.cityId}`, { replace: true });
		});
	};

	return (
		<CitiesContext.Provider
			value={{
				cityId,
				cities: citiesWithKeys,
				setCity,
			}}
		>
			{children}
		</CitiesContext.Provider>
	);
};
