import type { CartItem } from "@/shared/lib/cart/types";

export type CartStore = {
	categories: CartItem[];
	seats: CartItem[];
} | null;

type CartStorePayload = {
	categories: CartItem[];
	seats: CartItem[];
};

const CART_STORE_KEY = "_reservation";

class CartStorage {
	setItems(payload: CartStorePayload) {
		return localStorage.setItem(CART_STORE_KEY, JSON.stringify(payload));
	}
	getItems() {
		const rawData = localStorage.getItem(CART_STORE_KEY);
		if (rawData) {
			return JSON.parse(rawData) as CartStore;
		}

		return null;
	}
	clear() {
		return localStorage.removeItem(CART_STORE_KEY);
	}
}

export const cartStorage = new CartStorage();
