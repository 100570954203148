import { post } from "../fetch";

export type GetSeatList = {
	eventId: number;
	eventTime: string;
	date: string;
	placement: boolean;
	tariffPlanList: Array<{
		tariffPlanId: number;
		tariffPlanName: string;
	}>;
	categoryList: Array<{
		availability: number;
		categoryPriceId: number;
		categoryPriceName: string;
		price: number;
		tariffIdMap: Record<number, number>;
	}>;
	categoryLimitList: Array<{
		remainder: number;
		categoryList: Array<number>;
	}>;
	seatsPackage: Array<string[]>;
	availability: number;
};

export type ReserveResult = {
	cartTimeout: number;
	currency: string;
	seatList: Array<{
		available: boolean;
		categoryPriceId: number;
		location: { sector: string; row: string; number: string };
		number: string;
		row: string;
		sector: string;
		new: boolean;
		placement: boolean;
		price: number;
		seatId: number;
		error?: string;
	}>;
};

export type UnreserveResult = {
	cartTimeout: number;
	seatList: Array<{
		available: boolean;
		categoryPriceId: number;
		location: { sector: string; row: string; number: string };
		number: string;
		row: string;
		sector: string;
		new: boolean;
		placement: boolean;
		price: number;
		seatId: number;
	}>;
};

export async function getSeatList(args: { eid: string }) {
	return post<GetSeatList>("/get_seat_list", args);
}

export async function reserve(args: {
	eid: number;
	categories?: Array<{ cid: number; tid?: number; qty: number }>;
	seats?: Array<{ sid: number; tid?: number; fanid?: string }>;
}) {
	return post<ReserveResult>("/reserve", args);
}

export async function unreserveAll(args?: { eid?: string }) {
	return post<UnreserveResult>("/unreserve_all", {
		eid: args?.eid,
	});
}

export async function getSchema(args: { eid: string | number }) {
	return post<string>("/get_schema", args);
}
