import { createContext, useContext } from "react";

export const NewOrdersContext = createContext<{
	count: number;
	reservationExpiredAt: string | null;
} | null>(null);

type NewOrdersProviderProps = {
	children: React.ReactNode;
	orders: number;
	reservationExpiredAt: string | null;
};

export const NewOrdersProvider: React.FC<NewOrdersProviderProps> = ({
	children,
	orders,
	reservationExpiredAt,
}) => {
	return (
		<NewOrdersContext value={{ count: orders, reservationExpiredAt }}>
			{children}
		</NewOrdersContext>
	);
};

export function useNewOrdersContext() {
	const context = useContext(NewOrdersContext);

	if (!context) {
		throw new Error("Missing NewOrdersContext.Provider in the tree");
	}

	return context;
}
